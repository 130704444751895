import React, {Component} from 'react';
import resumeData from './data/resume.json';
import reactLogo from './images/icon-reactjs.svg';
import ResumePDF from './docs/Resume.PUBLIC.pdf';
import './Resume.scss';
import JSONPretty from 'react-json-pretty';
import includes from 'lodash/includes';
import isObject from 'lodash/isObject';
import clone from 'lodash/clone';
import cloneDeep from 'lodash/cloneDeep';
import isNil from 'lodash/isNil';
import isArray from 'lodash/isArray';

const checkKeyAccessibility = (keyName) => {
  return isObject(resumeData) ? includes(resumeData.accessibility, keyName) : false;
};

const getAccessibleKeyValue = (keyName) => {
  return isObject(resumeData) && checkKeyAccessibility(keyName) ? cloneDeep(resumeData[keyName]) : null;
};

const ALink = (linkUrl, linkText = 'link', target = '_self') => {
  if (isNil(linkUrl)) {
    return null;
  }

  return (
      <a className="Resume-link" href={linkUrl} target={target}>{linkText}</a>
  );
};

const MailtoALink = (email, linkText = 'link') => {
  if (isNil(email)) {
    return null;
  }

  return (
      ALink(`mailto:${email}`, linkText)
  );
};

const ResumePipeItem = (itemValue, itemClassName) => {
  if (isNil(itemValue)) {
    return itemValue;
  }

  return (
      <li className={`Resume-piped-item ${itemClassName}`}>{itemValue}</li>
  );
};

const IconALink = (linkUrl, iconClassName = 'fa', linkText, target = '_self') => {
  if (isNil(linkUrl)) {
    return null;
  }

  return (
      ALink(linkUrl, <i className={iconClassName} title={linkText}></i>, target)
  );
};

const stripProtocol = (url) => {
  const siteProtocolRegex = /^(https?|ftp):\/\//;

  if (isNil(url)) {
    return url;
  }

  return url.replace(siteProtocolRegex, '');
};

const ContactInfo = (privateContact = {}, publicContact = {}) => {
  return (
      <section className="Resume-contact-info">
        <ul className="Resume-contact-info-row Resume-list-piped-items">
          {ResumePipeItem(privateContact.address, 'Resume-contact-info-item Resume-contact-info-address')}
          {ResumePipeItem(privateContact.city, 'Resume-contact-info-item Resume-contact-info-city')}
          {ResumePipeItem(privateContact.state, 'Resume-contact-info-item Resume-contact-info-state')}
          {ResumePipeItem(privateContact.zip, 'Resume-contact-info-item Resume-contact-info-zip')}
        </ul>
        <ul className="Resume-contact-info-row Resume-list-piped-items">
          {ResumePipeItem(privateContact.phone, 'Resume-contact-info-item Resume-contact-info-phone')}
          {ResumePipeItem(MailtoALink(privateContact.email, privateContact.email), 'Resume-contact-info-item Resume-contact-info-email')}
          {ResumePipeItem(ALink(publicContact.website, stripProtocol(publicContact.website), '_self'), 'Resume-contact-info-item Resume-contact-info-website')}
          {ResumePipeItem(IconALink(publicContact.linkedin, 'fab fa-linkedin', stripProtocol(publicContact.linkedin), '_blank'), 'Resume-contact-info-item Resume-contact-info-website')}
        </ul>
      </section>
  );
};

const Summary = (summaryText) => {
  if (isNil(summaryText)) {
    return null;
  }

  return (
      <section className="Resume-section Resume-summary">
        <h2 className="Resume-section-title">Summary:</h2>
        <p className="Resume-summary-text">{summaryText}</p>
      </section>
  );
};

const SkillItem = ({skillDetail}) => {
  if (isNil(skillDetail)) {
    return null;
  }

  return (
      <li className="Resume-skill">
        <div className="Resume-skill-name" title={skillDetail.skill}>{skillDetail.skill}</div>
        <div className="Resume-skill-level">{skillDetail.level}</div>
      </li>
  );
};

const SkillsList = (skills) => {
  if (isNil(skills)) {
    return null;
  }

  const skillsClone = cloneDeep(skills);

  return (
      <section
          className={`Resume-section Resume-skills ${skillsClone.isVisible ? 'Resume-section-visible' : 'Resume-section-hidden'}`}>
        <h2 className="Resume-section-title">Skills:</h2>
        <ul className="Resume-skills-list">
          {skillsClone.list.map((skillDetail) => {
            return skillDetail.isVisible ?
                <SkillItem key={skillDetail.skillId} skillDetail={skillDetail}/> : null;
          })}
        </ul>
      </section>
  );
};

const DateRangeItem = ({startDate, endDate, dateClassName}) => {
  if (isNil(startDate) && isNil(endDate)) {
    return null;
  }

  return (
      <span className={dateClassName}>{startDate} - {endDate ? endDate : 'Present'}</span>
  );
};

const JobDescriptionItem = ({jobDescriptionDetail}) => {
  if (isNil(jobDescriptionDetail)) {
    return null;
  }

  return (
      <li className="Resume-job-description">
        {jobDescriptionDetail}
      </li>
  );
};

const JobDescriptionsList = (jobDescriptions) => {
  if (isArray(jobDescriptions) && jobDescriptions.length > 0) {
    const jobDescriptionsClone = clone(jobDescriptions);

    return (
        <ul className="Resume-job-descriptions-list">
          {jobDescriptionsClone.map((jobDescriptionDetail, index) => {
            return (
                <JobDescriptionItem key={index} jobDescriptionDetail={jobDescriptionDetail}/>
            );
          })}
        </ul>
    );
  } else {
    return null;
  }
};

const ExperienceItem = ({experienceItemDetail}) => {
  const experienceItemDetailClone = clone(experienceItemDetail);

  if (isNil(experienceItemDetailClone)) {
    return null;
  }

  return (
      <li className="Resume-experience">
        <ul className="Resume-experience-info Resume-list-piped-items Resume-list-piped-items-block-on-mobile">
          {ResumePipeItem(experienceItemDetailClone.title, 'Resume-experience-item Resume-experience-title')}
          {ResumePipeItem(<DateRangeItem
                  startDate={experienceItemDetailClone.startDate}
                  endDate={experienceItemDetailClone.endDate}
                  dateClassName="Resume-experience-dates"/>,
              'Resume-experience-item Resume-experience-dates')}
          {ResumePipeItem(experienceItemDetailClone.company, 'Resume-experience-item Resume-experience-company-name')}
          {ResumePipeItem(experienceItemDetailClone.location, 'Resume-experience-item Resume-experience-company-location')}
        </ul>
        {JobDescriptionsList(experienceItemDetailClone.jobDescriptions)}
      </li>
  );
};

const ExperienceList = (experiences) => {
  if (isNil(experiences)) {
    return null;
  }

  return (
      <section
          className={`Resume-section Resume-experiences ${experiences.isVisible ? 'Resume-section-visible' : 'Resume-section-hidden'}`}>
        <h2 className="Resume-section-title">Experience:</h2>
        <ul className="Resume-experience-list">
          {experiences.list.map((experienceItemDetail) => {
            return experienceItemDetail.isVisible ? <ExperienceItem key={experienceItemDetail.experienceId}
                                                                    experienceItemDetail={experienceItemDetail}/> : null
          })}
        </ul>
      </section>
  );
};

const EducationItem = ({educationDetail}) => {
  const {degreeInfo, startDate, endDate, school, location} = educationDetail;

  if (isNil(educationDetail)) {
    return null;
  }

  return (
      <li className="Resume-education-item">
        <ul className="Resume-education-info Resume-list-piped-items Resume-list-piped-items-block-on-mobile">
          {ResumePipeItem(degreeInfo.title, 'Resume-education-item Resume-education-title')}
          {ResumePipeItem(degreeInfo.description, 'Resume-education-item Resume-education-description')}
          {ResumePipeItem(<DateRangeItem
                  startDate={startDate}
                  endDate={endDate}
                  dateClassName="Resume-education-dates"/>,
              'Resume-education-item Resume-education-dates')}
          {ResumePipeItem(school, 'Resume-education-item Resume-education-school')}
          {ResumePipeItem(location, 'Resume-education-item Resume-education-location')}
        </ul>
      </li>
  );
};

const EducationList = (schools) => {
  if (isNil(schools)) {
    return null;
  }

  return (
      <section
          className={`Resume-section Resume-education ${schools.isVisible ? 'Resume-section-visible' : 'Resume-section-hidden'}`}>
        <h2 className="Resume-section-title">Education:</h2>
        <ul className="Resume-education-list">
          {schools.list.map((educationDetail) => {
            return educationDetail.isVisible ? <EducationItem key={educationDetail.educationId}
                                                              educationDetail={educationDetail}/> : null
          })}
        </ul>
      </section>
  );
};

const ResumeJobTitle = (title) => {
  if (isNil(title)) {
    return null;
  }

  return (
      <span className="Resume-job-title">({title})</span>
  );
};

const resumeJsonToggleOptionBtn = (isJsonVisible, handleToggleJsonClick) => {
  return (
      <button className="Resume-option Resume-json-toggle"
              title={`${isJsonVisible ? 'Click to hide Resume JSON' : 'Click to view Resume JSON'}`}
              onClick={handleToggleJsonClick}>
        <i className={`fas ${isJsonVisible ? 'fa-angle-double-down' : 'fa-angle-double-up'}`}></i>
      </button>
  );
};

const resumePrintOptionBtn = () => {
  const handlePrintClick = () => {
    window.print();
  };
  return (
      <button onClick={handlePrintClick} title="Click to print"
              className="Resume-option Resume-print"><i className="fas fa-print"></i></button>
  );
};

const resumePdfOptionBtn = () => {
  return (
      <a href={ResumePDF} title="Click to view PDF" target="_blank" rel="noopener noreferrer"
         className="Resume-option Resume-print"><i className="far fa-file-pdf"></i></a>
  );
};

class Resume extends Component {
  constructor(props) {
    super(props);
    this.state = {jsonToggleVisible: false};

    this.handleToggleJsonClick = this.handleToggleJsonClick.bind(this);
  }

  handleToggleJsonClick(e) {
    this.setState(prevState => ({
      jsonToggleVisible: !prevState.jsonToggleVisible
    }));
  }

  isJsonVisible() {
    return this.state.jsonToggleVisible;
  }


  ResumeHeader() {
    return (
        <header className="Resume-header">
          <h1 className="Resume-title">
            {getAccessibleKeyValue('firstName')} {getAccessibleKeyValue('lastName')} {ResumeJobTitle(getAccessibleKeyValue('title'))}
          </h1>
          {ContactInfo(getAccessibleKeyValue('privateContact'), getAccessibleKeyValue('publicContact'))}
        </header>
    );
  }

  ResumeDetail() {
    return (
        <section className="Resume-detail">
          {Summary(getAccessibleKeyValue('summary'))}
          {SkillsList(getAccessibleKeyValue('skills'))}
          {ExperienceList(getAccessibleKeyValue('experience'))}
          {EducationList(getAccessibleKeyValue('education'))}
        </section>
    );
  }


  ResumeFooter() {
    return (
        <footer className="Resume-footer">
          <div className="Resume-json">
            <JSONPretty id="json-pretty" json={resumeData}></JSONPretty>
          </div>
          <nav className="Resume-footer-nav">
            <div className="Resume-built-with">Built with
              <a href="https://facebook.github.io/react/"
                 className="Resume-built-with-link" title="ReactJS">
                <img className="Resume-built-with-logo" src={reactLogo} alt="ReactJS Logo"/>
              </a>
            </div>
            <div className="Resume-options">
              {resumePdfOptionBtn()}
              {resumePrintOptionBtn()}
              {resumeJsonToggleOptionBtn(this.isJsonVisible(), this.handleToggleJsonClick)}
            </div>
          </nav>
        </footer>
    );
  }

  render() {
    return (
        <div className={`Resume ${this.isJsonVisible() ? 'Resume-json-visible' : 'Resume-json-hidden'}`}>
          {this.ResumeHeader()}
          {this.ResumeDetail()}
          {this.ResumeFooter()}
        </div>
    );
  }
}

export default Resume;
